import Button from '@ingka/button';
import React from 'react';
import gear from '@ingka/ssr-icon/paths/gear';
import useData from 'hooks/useData';
import useNavigate from 'components/Router/useNavigate';
import { useNestedTranslation } from 'hooks/useNestedTranslation';
import classes from './ConfigurationNavButton.module.scss';

const ConfigurationNavButton = () => {
    const t = useNestedTranslation('navigation');
    const { currentCountry } = useData();
    const navigate = useNavigate();

    return (
        <div className={classes['config-nav-button']}>
            <Button
                id="config-navigation-button"
                data-testid="config-navigation-button"
                iconOnly
                size="small"
                ssrIcon={gear}
                type="tertiary"
                disabled={!currentCountry}
                onClick={() => {
                    navigate({
                        to: {
                            pathname: '/configuration',
                        },
                    });
                }}
                // Using text with iconOnly for accessibility
                text={t('configurationButtonDescription')}
            />
        </div>
    );
};

export default ConfigurationNavButton;
