import { useTranslation } from 'react-i18next';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import useApp from 'hooks/useApp';
import { configRangeIsContract } from 'hooks/useGrid/gridFunctionsShared';
import { OpenEndedRange } from 'types/api';
import { ContractRange } from 'types/coworker';
import { TFunction } from 'i18next';
import useData from 'hooks/useData';
import { displayContractRangeWithDescription } from 'utils/text';
import { sortContractRangeDesc } from 'utils/sorting';
import { GridData } from 'types/appContext';
import Text from '@ingka/text';
import ContractMixCard from './ContractMixCard';
import classes from './ContractMix.module.scss';

export const displayRange = (rangeData: ContractRange | OpenEndedRange, t: TFunction<'translation', undefined, 'translation'>) => {
    if (configRangeIsContract(rangeData)) {
        return displayContractRangeWithDescription(rangeData);
    }

    if (rangeData.min === null || rangeData.min === 0) {
        return `0 < ${rangeData.max} ${t('HOURS_ABBREVIATED')}`;
    }

    if (rangeData.max === null) {
        return `${rangeData.min} ${t('HOURS_ABBREVIATED')} +`;
    }

    return `${rangeData.min} - < ${rangeData.max} ${t('HOURS_ABBREVIATED')}`;
};

type ContractMixItem = {
    number: number;
    percentage: number;
    range: ContractRange | OpenEndedRange;
};
/**
 * Sorts the contract mix data by range, lowest first.
 * Note: Nulls are treated differently for OpenEndedRange and ContractRange.
 * OpenEndedRange: min: null is treated as 0, max: null is treated as infinity.
 * ContractRange: min: null is treated as min=max.
 */
const sortMixByRange = (a: ContractMixItem, b: ContractMixItem) => {
    // Note: Nulls are treated differently for OpenEndedRange and ContractRange.
    // OpenEndedRange: null is treated as 0 for min and infinity for max.
    // ContractRange: null is treated as min=max.
    if (configRangeIsContract(a.range) && configRangeIsContract(b.range)) {
        return sortContractRangeDesc(a.range, b.range);
    }

    // Note: either both are ContractRange or both are OpenEndedRange. Ternary is for ts
    const aRangeValues = configRangeIsContract(a.range) ? a.range.range : a.range;
    const bRangeValues = configRangeIsContract(b.range) ? b.range.range : b.range;
    if (aRangeValues.min === null || bRangeValues.max === null) {
        return -1;
    }
    if (bRangeValues.min === null || aRangeValues.max === null) {
        return 1;
    }

    if (aRangeValues.max === bRangeValues.max) {
        return aRangeValues.min - bRangeValues.min;
    }

    return aRangeValues.max - bRangeValues.max;
};

/**
 * Extracts and formats the contract mix data for the first week of the selected time period.
 * Filters out any ranges which have no coworkers in the first week
 * Sorts the ranges using sortMixByRange
 */
export const formatContractMix = (gridData: GridData | undefined): ContractMixItem[] => gridData?.contractMixData
    ?.map(data => ({
        number: data.numberOfCoworkers[0],
        percentage: data.fractionOfCoworkers[0] * 100,
        range: data.rangeData,
    }))
    ?.filter(item => item.number > 0)
    ?.sort(sortMixByRange) ?? [];

const ContractMix = () => {
    const { t } = useTranslation();
    const { gridData } = useApp();
    const { isFetching } = useData();

    const initialMixData = formatContractMix(gridData);

    return (
        <LoadingWrapper isLoading={isFetching}>
            <div data-testid="contract-mix" className={classes['contract-mix__container']}>
                <div className={classes['contract-mix__card-container']}>
                    {initialMixData
                        .map((item, index) => (
                            <ContractMixCard
                                key={`contractMixCard-${index + 0}`}
                                label={displayRange(item.range, t)}
                                percentage={`${Math.round(item.percentage)}%`}
                                coworkers={t('NUMBER_COWORKERS', { count: item.number })}
                            />
                        )) }
                    {!initialMixData?.length && (
                    <Text data-testid="contract-mix-no-data-available" className={classes['contract-mix__no-data-container']}>
                        {t('GRAPH_NO_DATA_MESSAGE')}
                    </Text>
                    )}
                </div>
            </div>
        </LoadingWrapper>
    );
};

export default ContractMix;
