import React, { useMemo, useState, useRef } from 'react';
import useData from 'hooks/useData';
import { API } from 'services';
import { FileFormat } from 'types/fileFormat';
import Button from '@ingka/button';
import upload from '@ingka/ssr-icon/paths/arrow-up-to-line';
import { useToast } from 'hooks/useToast';
import { FileUploadErrors } from 'types/dataContext';
import useAuth from 'hooks/useAuth';
import { useNestedTranslation } from 'hooks/useNestedTranslation';
import classes from './FileUpload.module.scss';

type TypeFileUpload = {
    id: string;
    category: string,
    successCallback: (updatedAt: string, updatedBy: string, fileName: string) => void,
    errorCallback: (errorText: string, category: string, fileName?: string, errors?: FileUploadErrors[]) => void,
    disabled?: boolean,
    scenarioId?: string,
    uploadButtonAriaLabel: string,
};

const FileUpload = ({ id, category, successCallback, errorCallback, disabled, scenarioId, uploadButtonAriaLabel }: TypeFileUpload) => {
    const { currentCountry, currentUnit, currentUnitType, getUploadedFileData } = useData();
    const { displayToast } = useToast();
    const t = useNestedTranslation('toolbar.capacityFactors');
    const { access } = useAuth();
    const hasUploadFileAccess = access?.api?.uploadInputs;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const uploadFile = useMemo(() => (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true);
        const file = e.target.files?.[0];
        if (!file) {
            errorCallback(t('fileMissing'), category);

            return;
        }
        const fileSize = file.size; // File Size in bytes
        const maxFileSize = 1e+7; // 1e+7 Bytes is Equal to 10MB
        if (fileSize > maxFileSize) {
            errorCallback(t('uploadFailedSizeLimit'), category);
            setIsUploading(false);
        } else {
            const formData = new FormData();
            formData.append('file', file);
            API().uploadFile(currentCountry, currentUnit, currentUnitType, category, formData, scenarioId)
                .then(async response => {
                    const { updatedAt, updatedBy, fileName, module, errorText, errors } = response;
                    if (!errors && !errorText) {
                        displayToast({ title: t.global('success'), message: t('uploadSuccessful') });
                        if (updatedAt && updatedBy) {
                            successCallback(updatedAt, updatedBy, fileName);
                            getUploadedFileData(category);
                        }
                    } else {
                        errorCallback(t(errorText), module, fileName, errors);
                    }
                })
                .catch(err => {
                    displayToast({ title: t.global('error'), message: t('uploadFailed') });
                    errorCallback(err.errorText, category, err.fileName, err.errors);
                })
                .finally(() => {
                    setIsUploading(false);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUploading]);

    return (hasUploadFileAccess && (
        <div className={classes['upload-button']}>
            <Button
                className={classes.button}
                onClick={() => { fileInputRef?.current?.click(); }}
                text={isUploading ? t.global('uploading') : t.global('upload')}
                type="primary"
                ssrIcon={upload}
                data-testid={`upload-button--${id}`}
                iconPosition="trailing"
                size="small"
                fluid
                loading={isUploading}
                disabled={disabled || isUploading}
                aria-label={uploadButtonAriaLabel}
            />
            <input
                id={id}
                ref={fileInputRef}
                data-testid={`upload-input--${id}`}
                type="file"
                accept={FileFormat.xlsx}
                onClick={e => { (e.target as HTMLInputElement).value = ''; }} // Clears event value to trigger while selecting multiple times
                onChange={e => uploadFile(e)}
            />
        </div>
    )
    );
};

export default FileUpload;
